import createSimpleGetterSetter from '@/client/js/utils/getterSetter';
/**
 * Set the state for queueing methods.
 *
 * @type {{set: function(*): void, get: function(): Any, update: function(*): void, value: Any}|*}
 */
// eslint-disable-next-line import/prefer-default-export
export var queueState = createSimpleGetterSetter({
    functions: {}, // Queueing any methods.
    process: function () {
        var thisObj = queueState.get();
        if (thisObj === null || thisObj === void 0 ? void 0 : thisObj.functions) {
            for (var eventFunction in thisObj.functions) {
                if (typeof thisObj.functions[eventFunction] === 'function') {
                    thisObj.functions[eventFunction]();
                }
            }
        }
    },
});
