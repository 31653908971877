import adEvent from '@/platform/jw-player/client/js/analytics/event/adEvent';
import mediaEvent from '@/platform/jw-player/client/js/analytics/event/mediaEvent';
import playerEvent from '@/platform/jw-player/client/js/analytics/event/playerEvent';
import { clearMediaCustomDimensions, setMediaCustomDimensions, setPlayerCustomDimension, } from '@/platform/jw-player/client/js/analytics/gaCustomDimensions';
import gaEmbedTracking from '@/client/js/analytics/gaEmbedTracking';
import dependencyReady from '@/client/js/utils/dependencyReady';
import { mediaState } from '@/platform/jw-player/client/js/analytics/state/mediaState';
import { quartileTracking } from '@/platform/jw-player/client/js/analytics/utils';
import { gaSendStandardEvent, setGaAction } from '@/platform/jw-player/client/js/analytics/gaActions';
import { playerState } from '@/platform/jw-player/client/js/analytics/state/playerState';
import { queueState } from '@/platform/jw-player/client/js/analytics/state/queueState';
import { configState } from '@/platform/jw-player/client/js/analytics/state/configState';
/**
 * Analytics for the JW Player platform.
 * -------------------------------------
 * The logic in this plugin is intended to interact with the top level
 * analytics script found in nyp-video-player/client/src/js/analytics.
 *
 * JW Plugin Architecture docs:
 * Ref: https://developer.jwplayer.com/jwplayer/docs/jw8-add-a-custom-or-third-party-plugin
 *
 * @param {object} player The JW Player player object.
 * @param {object} config The JW Player player plugin configuration object.
 */
function jwAnalytics(player, config) {
    /**
     * Allow config settings to be accessible within the plugin.
     */
    configState.update(config);
    /**
     * Procedure for setting up the `Video Load Start` GA event.
     */
    var sendLoadStart = function () {
        // Clear out any GA custom dimensions that apply to the media item.
        clearMediaCustomDimensions();
        // Set the new custom dimensions.
        setMediaCustomDimensions();
        // Send a standardized GA event.
        gaSendStandardEvent('Video Load Start');
    };
    /**
     * Execute the logic to set up GA tracking.
     */
    var bootstrapAnalytics = function () {
        // Set the player dimensions.
        setPlayerCustomDimension(player, config);
        // Send the player events.
        playerEvent(player);
        // Setup the ad events.
        adEvent(player);
        // Setup the media events.
        mediaEvent(player, config);
        // Check the event queue if there are
        // more methods to be executed on load.
        var queue = queueState.get();
        // Run the queue.
        queue.process();
        // Clear the queue.
        queueState.set({
            functions: {},
        });
    };
    /**
     * Plugin logic for JW Player platform.
     *  - Set GA custom dimensions based on player media loads.
     *  - Trigger custom GA events from player interaction.
     */
    player.on('ready', function () {
        // Condition for the existence of the `window.gtag` object.
        if (typeof window.gtag !== 'undefined') {
            // Load GA.
            gaEmbedTracking(config.trackingId);
            // Wait for the `gtag` object to be set in the window.
            dependencyReady.waitFor('gtag', bootstrapAnalytics);
        }
        else {
            // Initialize analytics.
            bootstrapAnalytics();
        }
    });
    /**
     * Fired when the playlist index changes to a new playlist item. This
     * event occurs before the player begins playing the new playlist item.
     * The event returns an object of the `index` and the `item` object.
     * Ref: https://developer.jwplayer.com/jwplayer/docs/jw8-javascript-api-reference#jwplayeronplaylistitem.
     */
    player.on('playlistItem', function (itemObj) {
        var _a;
        var oldMediaTracker = mediaState.get();
        // Track this new video.
        mediaState.set((itemObj === null || itemObj === void 0 ? void 0 : itemObj.item) ? itemObj.item : {});
        // Reset our media tracker object properties.
        mediaState.update({
            // Volume tracking.
            volume: player.getVolume(), // Ref: https://developer.jwplayer.com/jwplayer/docs/jw8-javascript-api-reference#jwplayergetvolume
            // Quartile tracking.
            quartileBenchmarks: quartileTracking(parseFloat((_a = itemObj === null || itemObj === void 0 ? void 0 : itemObj.item) === null || _a === void 0 ? void 0 : _a.duration)),
            quartile: 25,
            // Media play count.
            playCount: parseInt(oldMediaTracker.playCount, 10) + 1,
            gaAction: (itemObj === null || itemObj === void 0 ? void 0 : itemObj.item) ? setGaAction(itemObj.item) : '',
        });
        // Update the player state.
        var playerStateObj = playerState.get();
        playerState.update({
            playCount: parseInt(playerStateObj.playCount, 10) + 1,
        });
        if (typeof window.gtag !== 'undefined') {
            sendLoadStart();
        }
        else {
            queueState.update({
                functions: {
                    sendLoadStart: sendLoadStart,
                },
            });
        }
    });
}
var registerPlugin = window.jwplayerPluginJsonp || window.jwplayer().registerPlugin;
// Register the plugin.
registerPlugin('jwanalytics.bundle.min', '8.0', jwAnalytics);
